import { Button, Drawer, Table, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";
import tagIcon from "../../assets/tagIcon.svg";
import { CloseOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { theme } from "antd";
import api from "../../API/api";
import { useAccountingHistory } from "../../Hooks/Accounting";

const { Title } = Typography;

interface Salary {
  id: number;
  month: string;
  year: number;
  number_of_tasks: number;
  total_points: number;
  salary_type: string;
  base_salary: string;
  performance_salary: string;
  total_salary: string;
}

interface Employee {
  id: number;
  first_name: string;
  last_name: string;
  username: string;
}

interface SalaryData {
  employee: Employee;
  salaries: Salary[];
  total: number;
}

interface SalaryTablesProps {
  data: SalaryData;
}

const AccountingHistory: React.FC = () => {
  const [open, setOpen] = useState(false);

  const [userData, setUserData] = useState<SalaryData | null>(null);

  const [years, setYears] = useState<number[]>([]);

  const themes = localStorage.getItem("theme") === "true" ? true : false;
  const { data, refetch, isLoading } = useAccountingHistory();

  const [selectedUser, setSelectedUser] = useState<any>(null);

  const handleRowClick = async (record: any, e: any) => {
    setSelectedUser(record);
    setOpen(true);

    const id = record.id;

    try {
      const response = await api.get(`/employee-salaries/${id}/`);
      setUserData(response.data);

      const newYears = Array.from(
        new Set(response.data.salaries.map((salary: Salary) => salary.year))
      ).map((year) => Number(year));
      setYears(newYears);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (userData && userData.salaries && userData.salaries.length > 0) {
      const newYears = Array.from(
        new Set(userData.salaries.map((salary: Salary) => Number(salary.year))) // `year` ni number formatga o'tkazamiz
      ).sort((a, b) => b - a); // Katta yildan kichik yilga qarab tartiblash

      setYears(newYears);
    }
  }, [userData]);

  const { token } = theme.useToken();

  return (
    <div style={{ paddingBottom: 40 }}>
      <Table
        size="small"
        loading={isLoading}
        dataSource={data?.map((u, i) => ({
          no: i + 1,
          ...u,
        }))}
        columns={[
          {
            title: <img src={tagIcon} alt="" />,
            dataIndex: "no",
            key: "no",
            width: "5%",
            align: "center",
          },
          {
            title: "Username",
            dataIndex: "username",
            key: "username",
          },
          {
            title: "Role",
            dataIndex: "role",
            key: "role",
          },
          {
            title: "Total Tasks",
            dataIndex: "total_number_of_tasks",
            key: "total_number_of_tasks",
          },
          {
            title: "Total Points",
            dataIndex: "total_earned_points",
            key: "total_earned_points",
          },
          {
            title: (
              <Tooltip title="Total Worked Months">
                <span>TWM</span>&nbsp;
                <QuestionCircleOutlined />
              </Tooltip>
            ),
            dataIndex: "salary_months_count",
            key: "salary_months_count",

            // render: (salary_months_count) => {
            //   const years = Math.floor(salary_months_count / 12);
            //   const remainingMonths = salary_months_count % 12;

            //   let result = "";
            //   if (years > 0) {
            //     result += `${years} year${years > 1 ? "s" : ""}`;
            //   }
            //   if (remainingMonths > 0) {
            //     result += `${years > 0 ? " " : ""}${remainingMonths} month${
            //       remainingMonths > 1 ? "s" : ""
            //     }`;
            //   }
            //   return result || "0 months";
            // },
          },
          {
            title: "Salary Type",
            dataIndex: "salary_type",
            render: (value: any, record: any) => {
              if (record.salary_type === "task_based") {
                return <p>Task Based</p>;
              } else if (record.salary_type === "hybrid") {
                return <p>Hybrid</p>;
              } else if (record.salary_type === "fixed") {
                return <p>Fixed</p>;
              } else {
                return <p>{record.salary_type}</p>; // Agar boshqa qiymat bo'lsa, oddiy qilib chiqariladi
              }
            },
            filters: [
              {
                text: "Hybrid",
                value: "hybrid",
              },
              {
                text: "Task Based",
                value: "task_based",
              },
              {
                text: "Fixed",
                value: "fixed",
              },
            ],
            filterMultiple: false,
            // defaultFilteredValue: ["hybrid"],
            onFilter: (value: any, record: any) => {
              return record.salary_type === value;
            },
          },
          {
            title: "Base Salary",
            dataIndex: "total_base_salary",
            render: (text: string, record: any) => (
              <p>${record?.total_base_salary}</p>
            ),
          },
          {
            title: "Performance Salary",
            dataIndex: "total_performance_salary",
            render: (text: string, record: any) => (
              <p>${record?.total_performance_salary}</p>
            ),
          },
          {
            title: "Total Charges",
            dataIndex: "total_charges",
            render: (text: string, record: any) => (
              <p>${record?.total_charges}</p>
            ),
          },
          {
            title: "Total Bonuses",
            dataIndex: "total_bonuses",
            render: (text: string, record: any) => (
              <p>${record?.total_bonuses}</p>
            ),
          },
          {
            title: "Total Salary",
            dataIndex: "total_earned_salary",
            key: "total_earned_salary",
            render: (text: string, record: any) => (
              <span>${record.total_earned_salary}</span>
            ),
          },
        ]}
        rowClassName={(record, index) =>
          index % 2 === 0 ? "odd-row" : "even-row"
        }
        bordered
        pagination={{
          pageSize: 10,
          size: "default",
          style: {
            margin: 0,
            justifyContent: "end",
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            backgroundColor: token.colorBgContainer,
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.4)",
            padding: "10px 0",
            zIndex: 1000,
          },
          showLessItems: true,
        }}
        // onRow={(record) => ({
        //   onClick: () => {
        //     if (record.user && record.user.id) {
        //       navigate(`/accounting/${record.user.id}`); // `user.id`ni olish
        //     } else {
        //       console.error("User ID mavjud emas");
        //     }
        //   },
        // })}

        onRow={(record) => ({
          onClick: (e) => handleRowClick(record, e),
        })}
      />
      <Drawer
        title={
          <Typography className="title">
            {selectedUser?.full_name?.trim()
              ? selectedUser.full_name
              : selectedUser?.username}
          </Typography>
        }
        placement="right"
        width={850}
        onClose={() => setOpen(false)}
        closable={false}
        open={open}
        extra={
          <Button
            type="text"
            icon={<CloseOutlined />}
            onClick={() => setOpen(false)}
          />
        }
      >
        <div className="info-div">
          <Typography
            style={{
              fontSize: 18,
              fontWeight: 700,
              lineHeight: "24px",
              letterSpacing: "-0.02em",
              marginBottom: 16,
            }}
          >
            Information
          </Typography>
          <div className="info-body">
            <div className="d-flex" style={{ justifyContent: "space-between" }}>
              <div>
                <tr>
                  <p className={!themes ? "sub" : "sub-dark"}>Username</p>
                  <p className={!themes ? "info" : "info-dark"}>
                    {selectedUser?.username}
                  </p>
                </tr>
                <tr>
                  <p className={!themes ? "sub" : "sub-dark"}>Role</p>
                  <p className={!themes ? "info" : "info-dark"}>
                    {selectedUser?.role}
                  </p>
                </tr>
                <tr>
                  <p className={!themes ? "sub" : "sub-dark"}>Total Tasks</p>
                  <p className={!themes ? "info" : "info-dark"}>
                    {selectedUser?.total_number_of_tasks}
                  </p>
                </tr>
                <tr>
                  <p className={!themes ? "sub" : "sub-dark"}>Total Points</p>
                  <p className={!themes ? "info" : "info-dark"}>
                    {selectedUser?.total_earned_points}
                  </p>
                </tr>
              </div>
              <div>
                <tr>
                  <p className={!themes ? "sub" : "sub-dark"}>
                    Total Worked Months
                  </p>
                  <p className={!themes ? "info" : "info-dark"}>
                    {selectedUser?.salary_months_count}
                  </p>
                </tr>
                <tr>
                  <p className={!themes ? "sub" : "sub-dark"}>Total Bonus</p>
                  <p className={!themes ? "info" : "info-dark"}>
                    $
                    {new Intl.NumberFormat("en-US").format(
                      selectedUser?.total_bonuses || 0
                    )}
                  </p>
                </tr>
                <tr>
                  <p className={!themes ? "sub" : "sub-dark"}>Total Charge</p>
                  <p className={!themes ? "info" : "info-dark"}>
                    $
                    {new Intl.NumberFormat("en-US").format(
                      selectedUser?.total_charges || 0
                    )}
                  </p>
                </tr>
                <tr>
                  <p className={!themes ? "sub" : "sub-dark"}>Total Salary</p>
                  <p className={!themes ? "info" : "info-dark"}>
                    $
                    {new Intl.NumberFormat("en-US").format(
                      selectedUser?.total_earned_salary || 0
                    )}
                  </p>
                </tr>
              </div>
            </div>
          </div>
        </div>

        <div style={{ marginTop: 24 }}>
          {years.length === 0 ? (
            <p>No data available for the years.</p>
          ) : (
            years.map((year) => (
              <div key={year} style={{ marginBottom: 32 }}>
                <Title level={3}>{year}</Title>
                {userData &&
                  userData.salaries
                    .filter((salary) => salary.year === year)
                    .map((filteredSalary) => (
                      <Table
                        key={filteredSalary.id}
                        dataSource={[filteredSalary]}
                        columns={[
                          {
                            title: <img src={tagIcon} alt="" />,
                            dataIndex: "no",
                            width: "5%",
                            align: "center",
                            render: (text, record, index) => index + 1,
                          },
                          { title: "Year", dataIndex: "year" },
                          { title: "Month", dataIndex: "month" },

                          {
                            title: "Total Charges",
                            dataIndex: "total_charges",
                            render: (text: string, record: any) => (
                              <span>${record.total_charges}</span>
                            ),
                          },
                          {
                            title: "Total Bonus",
                            dataIndex: "total_bonuses",
                            render: (text: string, record: any) => (
                              <span>${record.total_bonuses}</span>
                            ),
                          },
                          {
                            title: "Total Salary",
                            dataIndex: "total_salary",
                            render: (text: string, record: any) => (
                              <span>${record.total_salary}</span>
                            ),
                          },
                        ]}
                        rowKey="id"
                        pagination={false}
                        bordered
                      />
                    ))}
              </div>
            ))
          )}
        </div>
      </Drawer>
    </div>
  );
};

export default AccountingHistory;
