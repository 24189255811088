import { Table, Tooltip } from "antd";
import { TStat } from "../../types/Statistic/TStat";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "react-query";
import { QuestionCircleOutlined, QuestionOutlined } from "@ant-design/icons";
// @ts-ignore
import tagIcon from "../../assets/tagIcon.svg";

import { theme } from "antd";

const StatTable = ({
  data,
  isLoading,
  refetch,
}: {
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<TStat[], unknown>>;
  data: { data: TStat[] | undefined };
  isLoading: boolean;
}) => {
  const { token } = theme.useToken();

  return (
    <div>
      <Table
        size="small"
        loading={isLoading}
        dataSource={data?.data?.map((u, i) => ({
          no: i + 1,
          ...u,
        }))}
        columns={[
          {
            title: <img src={tagIcon} alt="" />,
            dataIndex: "no",
            key: "no",
            width: "5%",
          },
          {
            title: "Support specialist",
            dataIndex: "username",
            key: "username",
          },
          {
            title: "Team",
            dataIndex: "team_name",
            key: "team_name ",
          },
          {
            title: "Tasks",
            dataIndex: "number_of_tasks",
            key: "number_of_tasks",
            sorter: (a: any, b: any) => a.number_of_tasks - b.number_of_tasks,
            sortDirections: ["ascend", "descend"],
          },
          {
            title: "Points",
            dataIndex: "total_points",
            key: "total_points",
            sorter: (a: any, b: any) => a.total_points - b.total_points,
            sortDirections: ["ascend", "descend"],
          },
          // {
          //   title: (
          //     <div>
          //       <span>Salary</span> &nbsp;&nbsp;
          //       <Tooltip title="The calculation of salary begins at the start of the month and continues to the current day. Select a month to review salary details for prior periods.">
          //         <QuestionCircleOutlined />
          //       </Tooltip>
          //     </div>
          //   ),
          //   dataIndex: "salary",
          //   key: "salary",
          //   render: (text: string, record: any) => (
          //     <Tooltip
          //       title={
          //         <div>
          //           {record.salary_type === "hybrid" ? (
          //             <p>
          //               <strong>Fixed Amount:</strong> $
          //               {record.salary_base_amount}
          //             </p>
          //           ) : (
          //             ""
          //           )}
          //           <p>
          //             <strong>Performance based amount:</strong> $
          //             {record.performance_based_amount}
          //           </p>
          //         </div>
          //       }
          //       overlayStyle={{
          //         maxWidth: "700px",
          //       }}
          //     >
          //       <span>${record.salary}</span>
          //     </Tooltip>
          //   ),
          //   sorter: (a: any, b: any) => a.salary - b.total_points,
          //   sortDirections: ["ascend", "descend"],
          // },
        ]}
        pagination={{
          pageSize: 10,
          size: "default",
          style: {
            margin: 0,
            justifyContent: "end",
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            backgroundColor: token.colorBgContainer,
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.4)",
            padding: "10px 0",
            zIndex: 1000,
          },
          showLessItems: true,
        }}
        rowClassName={(record, index) =>
          index % 2 === 0 ? "odd-row" : "even-row"
        }
        bordered
      />
    </div>
  );
};

export default StatTable;

// The calculation of salary begins at the start of the month and continues to the current day. Select a month to review salary details for prior periods.
